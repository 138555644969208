import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';

const Requirement = t.intersection([
  t.type({
    name: nullable(t.string),
    orderIndex: t.number,
  }),
  t.partial({
    isBasicField: nullable(t.boolean),
  }),
], 'Requirement');

type Requirement = t.TypeOf<typeof Requirement>;
export { Requirement };
