import { GlobalNotificationResponse } from '../types/global-notification-response.type';
import { ApplicationNotificationModel } from './application-notification.model';

export class GlobalNotificationResponseModel implements GlobalNotificationResponse {
  readonly globalNotification: ApplicationNotificationModel | null;
  readonly privacyNotification: ApplicationNotificationModel;

  constructor(globalNotificationResponse: GlobalNotificationResponse) {
    this.globalNotification = globalNotificationResponse.globalNotification
      ? new ApplicationNotificationModel(globalNotificationResponse.globalNotification)
      : null;

    this.privacyNotification = new ApplicationNotificationModel(globalNotificationResponse.privacyNotification);
  }
}
