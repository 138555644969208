import { BaseModel } from './base.model';
import { EntityStatus } from '../types/entity-status.type';

export class EntityStatusModel extends BaseModel implements EntityStatus {
  readonly id: number;
  readonly name: string;
  readonly color: string;

  constructor(entityStatus: EntityStatus) {
    super();

    this.id = entityStatus.id;
    this.name = entityStatus.name;
    this.color = entityStatus.color;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
