import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';

const GuidName = t.type({
  id: t.string,
  name: nullable(t.string),
}, 'GuidName');

type GuidName = t.TypeOf<typeof GuidName>;
export { GuidName };
