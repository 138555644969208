import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { UserGuideTour } from './user-guide-tour.type';
import { UserPermissions } from './user-permissions.type';
import { Preference } from './preference.type';
import { User } from './user.type';

const CurrentUser = t.intersection([
  User,
  t.type({
    guideTours: nullable(t.array(UserGuideTour)),
    mystiqueKey: t.string,
    permissions: UserPermissions,
    preferences: nullable(t.array(Preference)),
    additionalData: t.record(t.string, t.boolean),
  }),
], 'CurrentUser');

type CurrentUser = t.TypeOf<typeof CurrentUser>;
export { CurrentUser };
