import { UserGuideTourModel } from './user-guide-tour.model';
import { UserPermissionsModel } from './user-permissions.model';
import { UserModel } from './user.model';
import { UserPermissions } from '../types/user-permissions.type';
import { PreferenceModel } from './preference.model';
import { CurrentUser } from '../types/current-user.type';

export class CurrentUserModel extends UserModel implements CurrentUser {
  readonly guideTours: UserGuideTourModel[] | null = null;
  readonly mystiqueKey: string;
  readonly permissions: UserPermissions;
  readonly preferences: PreferenceModel[] | null = null;
  readonly additionalData: Record<string, boolean>;

  constructor(currentUser: CurrentUser) {
    super(currentUser);

    if (currentUser.guideTours) {
      this.guideTours = currentUser.guideTours.map((item) => new UserGuideTourModel(item));
    }

    this.mystiqueKey = currentUser.mystiqueKey;
    this.permissions = new UserPermissionsModel(currentUser.permissions);

    if (currentUser.preferences) {
      this.preferences = currentUser.preferences.map((item) => new PreferenceModel(item));
    }

    this.additionalData = currentUser.additionalData;
  }
}
