import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';

const IdNameCode = t.type({
  id: t.number,
  code: t.string,
  name: nullable(t.string),
}, 'IdNameCode');

type IdNameCode = t.TypeOf<typeof IdNameCode>;
export { IdNameCode };
