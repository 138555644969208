import { BaseModel } from './base.model';
import { MdfCode } from '../types/mdf-code.type';

export class MdfCodeModel extends BaseModel implements MdfCode {
  readonly id: number;
  readonly name: string | null;
  readonly code: string | null;
  readonly count: number | undefined;

  constructor(mdfCode: MdfCode) {
    super();

    this.id = mdfCode.id;
    this.name = mdfCode.name;
    this.code = mdfCode.code;
    this.count = mdfCode.count;
  }

  /**
   * Returns the display value for an MDF code.
   */
  getDisplay(): string {
    return `${this.code} - ${this.name}`;
  }
}
