import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';

const IdNameDescription = t.type({
  id: t.number,
  name: nullable(t.string),
  description: nullable(t.string),
}, 'IdNameDescription');

type IdNameDescription = t.TypeOf<typeof IdNameDescription>;
export { IdNameDescription };
