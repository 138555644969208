import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';

const StringOrderedValue = t.type({
  orderIndex: t.number,
  value: nullable(t.string),
}, 'StringOrderedValue');

type StringOrderedValue = t.TypeOf<typeof StringOrderedValue>;
export { StringOrderedValue };
