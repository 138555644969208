import { ResourceResponse } from '../types/resource-response.type';
import { ResourceLinkSlimModel } from './resource-link-slim.model';

export class ResourceResponseModel implements ResourceResponse {
  readonly resourceLinks: ResourceLinkSlimModel[] | null = null;

  constructor(resourceResponse: ResourceResponse) {
    if (resourceResponse.resourceLinks) {
      this.resourceLinks = resourceResponse.resourceLinks
        .map((item) => new ResourceLinkSlimModel(item));
    }
  }
}
