import { EntityStatusModel } from '@abb-procure/data';
import { BooleanInput } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  ViewEncapsulation,
  booleanAttribute,
  computed,
  input,
  output,
} from '@angular/core';
import {
  MatExpansionModule,
  MatExpansionPanel,
} from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardComponent } from '../card/card.component';
import { NumberBadgeComponent } from '../number-badge/number-badge.component';
import { StatusBadgeComponent } from '../status-badge/status-badge.component';

const DEFAULT_PANEL_HEIGHT: string = '48';

@Component({
  selector: 'procure-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    NumberBadgeComponent,
    CardComponent,
    StatusBadgeComponent,
    MatTooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ExpansionPanelComponent {
  @ViewChild(MatExpansionPanel, { static: true })
  expansionPanel?: MatExpansionPanel;

  closed = output();
  opened = output();

  label = input<string | null>('');
  hint = input<string | null>(null);
  labelSuffix = input<string | null>('');
  labelLink = input<string | null>('');
  link = input<string | null>('');
  badge = input<number>();
  color = input<string>();

  cover = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  showZeroBadge = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  icon = input<string>('');
  expansionPanelClass = input<string[]>(['mat-elevation-z0']);
  status = input<EntityStatusModel>();
  panelHeight = input<string>(`${DEFAULT_PANEL_HEIGHT}px`);

  fromSAPAriba = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  groupIsRequired = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  loading = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  expanded = input<boolean, BooleanInput>(true, {
    transform: booleanAttribute,
  });

  hideToggle = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  disabled = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  hideHeader = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  flat = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  accent = input<boolean, BooleanInput>(false, {
    transform: booleanAttribute,
  });

  visibleHeight = computed(() => {
    if (this.label()?.length === 0 && this.hideToggle()) {
      return '0';
    }

    return this.panelHeight();
  });

  close(): void {
    this.expansionPanel?.close();
  }

  open(): void {
    this.expansionPanel?.open();
  }

  toggle(): void {
    this.expansionPanel?.toggle();
  }
}
