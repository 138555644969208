import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';

const StorageUploadSettings = t.type({
  host: nullable(t.string),
  container: nullable(t.string),
  token: nullable(t.string),
}, 'StorageUploadSettings');

type StorageUploadSettings = t.TypeOf<typeof StorageUploadSettings>;
export { StorageUploadSettings };
