import { BaseModel } from './base.model';
import { IdNameCode } from '../types/id-name-code.type';

export class IdNameCodeModel extends BaseModel implements IdNameCode {
  readonly id: number;
  readonly code: string;
  readonly name: string | null;

  constructor(codeIdName: IdNameCode) {
    super();

    this.id = codeIdName.id;
    this.code = codeIdName.code;
    this.name = codeIdName.name;
  }

  getDisplay(): string {
    if (this.code && this.name) {
      return `${this.code} - ${this.name}`;
    }

    if (this.code) {
      return this.code;
    }

    return `${this.name}`;
  }
}
