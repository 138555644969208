<div
  class="entity-badge"
  [class.entity-badge--copyable]="linked()"
>
  <procure-badge
    class="entity-badge__badge"
    [link]="linked() ? '/' + label() : null"
    [label]="label()"
    [class.entity-badge__badge--clickable]="clickable()"
    [backgroundColor]="backgroundColor()"
    [openInNewTab]="openInNewTab()"
    [condensed]="condensed()"
    [matTooltip]="tooltip() || ''"
  />
  @if (linked() && !copyNumber()) {
    <button
      class="entity-badge__copy"
      type="button"
      matTooltip="Copy link"
      (click)="copyLink()"
    >
      <mat-icon class="entity-badge__copy-icon">link</mat-icon>
    </button>
  }
  @if (copyNumber()) {
    <button
      class="entity-badge__copy"
      type="button"
      matTooltip="Copy Number"
      (click)="copyEntityNumber()"
    >
      <mat-icon class="entity-badge__copy-icon">content_copy</mat-icon>
    </button>
  }
</div>
