import * as t from 'io-ts';

const UserGuideTour = t.type({
  id: t.string,
  confirmedCount: t.number,
  markedAsCompleted: t.boolean,
}, 'UserGuideTour');

type UserGuideTour = t.TypeOf<typeof UserGuideTour>;
export { UserGuideTour };
