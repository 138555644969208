import { BaseModel } from './base.model';
import { DefaultCriteriaDimension } from '../types/default-criteria-dimension.type';

export class DefaultCriteriaDimensionModel extends BaseModel implements DefaultCriteriaDimension {
  readonly id: number;
  readonly name: string | null;
  readonly weight: number;
  readonly order: number;

  constructor(templateDefaultDimension: DefaultCriteriaDimension) {
    super();

    this.id = templateDefaultDimension.id;
    this.name = templateDefaultDimension.name;
    this.weight = templateDefaultDimension.weight;
    this.order = templateDefaultDimension.order;
  }
}
