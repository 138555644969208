import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';

const DefaultCriteriaDimension = t.type({
  id: t.number,
  name: nullable(t.string),
  weight: t.number,
  order: t.number,
}, 'DefaultCriteriaDimension');

type DefaultCriteriaDimension = t.TypeOf<typeof DefaultCriteriaDimension>;
export { DefaultCriteriaDimension };
