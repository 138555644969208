import { BaseModel } from './base.model';
import { IdNameDescription } from '../types/id-name-description.type';

export class IdNameDescriptionModel extends BaseModel implements IdNameDescription {
  readonly id: number;
  readonly name: string | null;
  readonly description: string | null;

  constructor(idName: IdNameDescription) {
    super();

    this.id = idName.id;
    this.name = idName.name;
    this.description = idName.description;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
