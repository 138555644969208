import * as t from 'io-ts';

const EntityStatus = t.type({
  id: t.number,
  name: t.string,
  color: t.string,
}, 'EntityStatus');

type EntityStatus = t.TypeOf<typeof EntityStatus>;
export { EntityStatus };
