import { BaseModel } from './base.model';
import { IdName } from '../types/id-name.type';

export class IdNameModel extends BaseModel implements IdName {
  readonly id: number;
  readonly name: string | null;

  constructor(idName: IdName) {
    super();

    this.id = idName.id;
    this.name = idName.name;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
