import { nullable } from '@abb-procure/api';
import * as t from 'io-ts';
import { IdName } from './id-name.type';

const UserSlim = t.intersection([
  IdName,
  t.type({
    email: nullable(t.string),
    avatarUrl: nullable(t.string),
  }),
], 'UserSlim');

type UserSlim = t.TypeOf<typeof UserSlim>;
export { UserSlim };
