import { IdNameModel } from './id-name.model';
import { TermsAndConditions } from '../types/terms-and-conditions.type';

export class TermsAndConditionsModel extends IdNameModel implements TermsAndConditions {
  readonly isCustomized: boolean;
  readonly url: string | null;

  constructor(termsAndConditions: TermsAndConditions) {
    super(termsAndConditions);

    this.isCustomized = termsAndConditions.isCustomized;
    this.url = termsAndConditions.url;
  }
}
