import { BaseModel } from './base.model';
import { GuidName } from '../types/guid-name.type';

export class GuidNameModel extends BaseModel implements GuidName {
  readonly id: string;
  readonly name: string | null;

  constructor(guidName: GuidName) {
    super();

    this.id = guidName.id;
    this.name = guidName.name;
  }

  getDisplay(): string {
    return `${this.name}`;
  }
}
