import { TermsAndConditionsModel } from './terms-and-conditions.model';
import { TermsAndConditionsCategory } from '../types/terms-and-conditions-category.type';
import { IdNameModel } from './id-name.model';

export class TermsAndConditionsCategoryModel extends IdNameModel implements TermsAndConditionsCategory {
  readonly termsAndConditions: TermsAndConditionsModel[] | null = null;

  constructor(termsAndConditionsCategory: TermsAndConditionsCategory) {
    super(termsAndConditionsCategory);

    if (termsAndConditionsCategory.termsAndConditions) {
      this.termsAndConditions = termsAndConditionsCategory.termsAndConditions
        .map((item) => new TermsAndConditionsModel(item));
    }
  }
}
