import { Injectable, computed, inject, signal } from '@angular/core';
import { deepEqual } from 'fast-equals';
import { ContractualMilestoneStatusModel } from '../models/contractual-milestone-status.model';
import { ContractualMilestoneModel } from '../models/contractual-milestone.model';
import { CostAllocationModel } from '../models/cost-allocation.model';
import { DefaultCriteriaDimensionModel } from '../models/default-criteria-dimension.model';
import { DeliveryDestinationModel } from '../models/delivery-destination.model';
import { EntityStatusModel } from '../models/entity-status.model';
import { IdNameCodeModel } from '../models/id-name-code.model';
import { IdNameDescriptionModel } from '../models/id-name-description.model';
import { IdNameModel } from '../models/id-name.model';
import { ResourceVariablesModel } from '../models/management/resources/resource-variables.model';
import { OfferedLeadTimeModel } from '../models/offered-lead-time.model';
import { OrderAcknowledgementReviewModel } from '../models/order-acknowledgement-review.model';
import { ResourceLinkSlimModel } from '../models/resource-link-slim.model';
import { SupplierSendTypeModel } from '../models/supplier-send-type.model';
import { TermsAndConditionsCategoryModel } from '../models/terms-and-conditions-category.model';
import { LookupDataApiService } from '../services/lookup-data-api.service';
import { RequirementFields } from '../types/requirements-fields.type';
import { StateStorage, StorageStrategy } from '../utils/state-storage.util';

export interface LookupDataStateShape {
  eTag: string;
  menu: ResourceLinkSlimModel[];
  countries: IdNameCodeModel[];
  currencies: IdNameCodeModel[];
  incotermsTypes: IdNameCodeModel[];
  paymentTerms: IdNameModel[];
  termsAndConditionCategories: TermsAndConditionsCategoryModel[];
  wbsCodes: IdNameModel[];
  offeredLeadTimes: OfferedLeadTimeModel[];
  deliveryDestinationTypes: DeliveryDestinationModel[];
  furtherDeliveryDestinationTypes: DeliveryDestinationModel[];
  costAllocationTypes: CostAllocationModel[];
  supplierSendTypes: SupplierSendTypeModel[];
  projectProcurementTypes: IdNameModel[];
  projectClassifications: IdNameModel[];
  projectTypeSupplies: IdNameModel[];
  projectStatuses: EntityStatusModel[];
  projectInExecutionSubstatuses: IdNameModel[];
  requestForRequestForQuotationStatuses: EntityStatusModel[];
  requestForQuotationStatuses: EntityStatusModel[];
  purchaseRequisitionStatuses: EntityStatusModel[];
  quotationStatuses: IdNameModel[];
  orderAcknowledgementReviews: OrderAcknowledgementReviewModel[];
  contractualMilestones: ContractualMilestoneModel[];
  contractualMilestoneStatuses: ContractualMilestoneStatusModel[];
  supplierTypes: SupplierSendTypeModel[];
  requestForRequestForQuotationClassifications: IdNameModel[];
  requestForRequestForQuotationSubstatuses: IdNameModel[];
  quotationSubstatuses: IdNameModel[];
  requestForQuotationSubstatuses: IdNameModel[];
  templateRequestForRequestForQuotationStatuses: EntityStatusModel[];
  categoryStatuses: EntityStatusModel[];
  requirementFields: RequirementFields;
  notificationReminderExecutionPeriod: IdNameModel[];
  categoryDimensionTaskStatuses: EntityStatusModel[];
  strategyDimensionLevers: IdNameDescriptionModel[];
  mdfCodes: IdNameCodeModel[];
  defaultDimensions: DefaultCriteriaDimensionModel[];
  resourceVariables: ResourceVariablesModel[];
  tsp: ResourceLinkSlimModel | null;
  gisLink: ResourceLinkSlimModel | null;
  supplierEvaluationEvaluatorRoles: IdNameModel[];
}

const initialState: LookupDataStateShape = {
  eTag: '',
  menu: [],
  countries: [],
  currencies: [],
  incotermsTypes: [],
  paymentTerms: [],
  termsAndConditionCategories: [],
  wbsCodes: [],
  offeredLeadTimes: [],
  deliveryDestinationTypes: [],
  furtherDeliveryDestinationTypes: [],
  costAllocationTypes: [],
  supplierSendTypes: [],
  projectProcurementTypes: [],
  projectClassifications: [],
  projectTypeSupplies: [],
  projectStatuses: [],
  projectInExecutionSubstatuses: [],
  requestForRequestForQuotationStatuses: [],
  requestForQuotationStatuses: [],
  templateRequestForRequestForQuotationStatuses: [],
  categoryStatuses: [],
  purchaseRequisitionStatuses: [],
  quotationStatuses: [],
  orderAcknowledgementReviews: [],
  contractualMilestones: [],
  contractualMilestoneStatuses: [],
  supplierTypes: [],
  requestForRequestForQuotationClassifications: [],
  requestForRequestForQuotationSubstatuses: [],
  quotationSubstatuses: [],
  requestForQuotationSubstatuses: [],
  requirementFields: { commercial: [], technical: [], logistical: [] },
  notificationReminderExecutionPeriod: [],
  categoryDimensionTaskStatuses: [],
  strategyDimensionLevers: [],
  mdfCodes: [],
  defaultDimensions: [],
  resourceVariables: [],
  tsp: null,
  gisLink: null,
  supplierEvaluationEvaluatorRoles: [],
};

@Injectable({
  providedIn: 'root',
})
export class LookupDataState {
  readonly eTag = computed(() => this.state().eTag);
  readonly menu = computed(() => this.state().menu, { equal: deepEqual });
  readonly tsp = computed(() => this.state().tsp, { equal: deepEqual });
  readonly gisLink = computed(() => this.state().gisLink, { equal: deepEqual });

  readonly countries = computed(() => this.state().countries, {
    equal: deepEqual,
  });

  readonly currencies = computed(() => this.state().currencies, {
    equal: deepEqual,
  });

  readonly incotermsTypes = computed(() => this.state().incotermsTypes, {
    equal: deepEqual,
  });

  readonly paymentTerms = computed(() => this.state().paymentTerms, {
    equal: deepEqual,
  });

  readonly strategyDimensionLevers = computed(
    () => this.state().strategyDimensionLevers,
    { equal: deepEqual },
  );

  readonly termsAndConditionCategories = computed(
    () => this.state().termsAndConditionCategories,
    { equal: deepEqual },
  );

  readonly wbsCodes = computed(() => this.state().wbsCodes, {
    equal: deepEqual,
  });

  readonly offeredLeadTimes = computed(() => this.state().offeredLeadTimes, {
    equal: deepEqual,
  });

  readonly deliveryDestinationTypes = computed(
    () => this.state().deliveryDestinationTypes,
    { equal: deepEqual },
  );

  readonly furtherDeliveryDestinationTypes = computed(
    () => this.state().furtherDeliveryDestinationTypes,
    { equal: deepEqual },
  );

  readonly costAllocationTypes = computed(
    () => this.state().costAllocationTypes,
    { equal: deepEqual },
  );

  readonly supplierSendTypes = computed(() => this.state().supplierSendTypes, {
    equal: deepEqual,
  });

  readonly projectProcurementTypes = computed(
    () => this.state().projectProcurementTypes,
    { equal: deepEqual },
  );

  readonly projectClassifications = computed(
    () => this.state().projectClassifications,
    { equal: deepEqual },
  );

  readonly projectTypeSupplies = computed(
    () => this.state().projectTypeSupplies,
    { equal: deepEqual },
  );

  readonly projectStatuses = computed(() => this.state().projectStatuses, {
    equal: deepEqual,
  });

  readonly projectInExecutionSubstatuses = computed(
    () => this.state().projectInExecutionSubstatuses,
    { equal: deepEqual },
  );

  readonly requestForRequestForQuotationStatuses = computed(
    () => this.state().requestForRequestForQuotationStatuses,
    { equal: deepEqual },
  );

  readonly templateRequestForRequestForQuotationStatuses = computed(
    () => this.state().templateRequestForRequestForQuotationStatuses,
    { equal: deepEqual },
  );

  readonly categoryStatuses = computed(() => this.state().categoryStatuses, {
    equal: deepEqual,
  });

  readonly requestForQuotationStatuses = computed(
    () => this.state().requestForQuotationStatuses,
    { equal: deepEqual },
  );

  readonly purchaseRequisitionStatuses = computed(
    () => this.state().purchaseRequisitionStatuses,
    { equal: deepEqual },
  );

  readonly quotationStatuses = computed(() => this.state().quotationStatuses, {
    equal: deepEqual,
  });

  readonly orderAcknowledgementReviews = computed(
    () => this.state().orderAcknowledgementReviews,
    { equal: deepEqual },
  );

  readonly contractualMilestones = computed(
    () => this.state().contractualMilestones,
    { equal: deepEqual },
  );

  readonly contractualMilestoneStatuses = computed(
    () => this.state().contractualMilestoneStatuses,
    { equal: deepEqual },
  );

  readonly supplierTypes = computed(() => this.state().supplierTypes, {
    equal: deepEqual,
  });

  readonly requestForRequestForQuotationClassifications = computed(
    () => this.state().requestForRequestForQuotationClassifications,
    { equal: deepEqual },
  );

  readonly requestForRequestForQuotationSubstatuses = computed(
    () => this.state().requestForRequestForQuotationSubstatuses,
    { equal: deepEqual },
  );

  readonly quotationSubstatuses = computed(
    () => this.state().quotationSubstatuses,
    { equal: deepEqual },
  );

  readonly requestForQuotationSubstatuses = computed(
    () => this.state().requestForQuotationSubstatuses,
    { equal: deepEqual },
  );

  readonly requirementFields = computed(() => this.state().requirementFields, {
    equal: deepEqual,
  });

  readonly notificationReminderExecutionPeriod = computed(
    () => this.state().notificationReminderExecutionPeriod,
    { equal: deepEqual },
  );

  readonly categoryDimensionTaskStatuses = computed(
    () => this.state().categoryDimensionTaskStatuses,
    { equal: deepEqual },
  );

  readonly mdfCodes = computed(() => this.state().mdfCodes, {
    equal: deepEqual,
  });

  readonly defaultDimensions = computed(() => this.state().defaultDimensions, {
    equal: deepEqual,
  });

  readonly resourceVariables = computed(() => this.state().resourceVariables, {
    equal: deepEqual,
  });

  readonly defaultBiddingRules = computed(
    () =>
      this.resourceVariables().find(
        (item) => item.uniqueCode === 'DefaultBiddingRules',
      )?.value ?? '',
  );

  readonly supplierEvaluationEvaluatorRoles = computed(
    () => this.state().supplierEvaluationEvaluatorRoles,
    { equal: deepEqual },
  );

  private readonly state = signal({ ...initialState });
  private readonly lookupDataApiService = inject(LookupDataApiService);

  constructor() {
    StateStorage.init(
      this.state,
      'lookupData',
      StorageStrategy.LocalStorage,
      this.afterDeserialize,
    );
  }

  async fetchETag(): Promise<void> {
    const response = await this.lookupDataApiService.getETag();

    if (this.eTag() === response) {
      return;
    }

    this.state.update((state) => ({
      ...state,
      eTag: response ?? '',
    }));

    this.fetchLookupData();
  }

  async fetchMenu(): Promise<void> {
    const response = await this.lookupDataApiService.getMenu();

    if (!response?.resourceLinks) {
      return;
    }

    this.state.update((state) => ({
      ...state,
      menu: response.resourceLinks ?? [],
    }));
  }

  async fetchTSP(): Promise<void> {
    const response = await this.lookupDataApiService.getTSP();

    if (!response) {
      return;
    }

    this.state.update((state) => ({
      ...state,
      tsp: response ?? null,
    }));
  }

  async fetchSupportLink(): Promise<void> {
    const response = await this.lookupDataApiService.getSupportLink();

    if (!response) {
      return;
    }

    this.state.update((state) => ({
      ...state,
      gisLink: response ?? null,
    }));
  }

  // eslint-disable-next-line max-lines-per-function
  async fetchLookupData(): Promise<void> {
    const response = await this.lookupDataApiService.getBaseData();

    if (!response) {
      return;
    }

    this.state.update((state) => ({
      ...state,
      eTag: response.eTag,
      countries: response.countries,
      currencies: response.currencies,
      incotermsTypes: response.incotermsTypes,
      paymentTerms: response.paymentTerms,
      termsAndConditionCategories: response.termsAndConditionCategories,
      wbsCodes: response.wbsCodes,
      offeredLeadTimes: response.offeredLeadTimes,
      deliveryDestinationTypes: response.deliveryDestinationTypes,
      furtherDeliveryDestinationTypes: response.furtherDeliveryDestinationTypes,
      costAllocationTypes: response.costAllocationTypes,
      supplierSendTypes: response.supplierSendTypes,
      projectProcurementTypes: response.projectProcurementTypes,
      projectClassifications: response.projectClassifications,
      projectTypeSupplies: response.projectTypeSupplies,
      projectStatuses: response.projectStatuses,
      projectInExecutionSubstatuses: response.projectInExecutionSubstatuses,
      requestForRequestForQuotationStatuses:
        response.requestForRequestForQuotationStatuses,
      requestForQuotationStatuses: response.requestForQuotationStatuses,
      templateRequestForRequestForQuotationStatuses:
        response.templateRequestForRequestForQuotationStatuses,
      categoryStatuses: response.categoryStatuses,
      purchaseRequisitionStatuses: response.purchaseRequisitionStatuses,
      quotationStatuses: response.quotationStatuses,
      orderAcknowledgementReviews: response.orderAcknowledgementReviews,
      contractualMilestones: response.contractualMilestones,
      contractualMilestoneStatuses: response.contractualMilestoneStatuses,
      supplierTypes: response.supplierTypes,
      requestForRequestForQuotationClassifications:
        response.requestForRequestForQuotationClassifications,
      requestForRequestForQuotationSubstatuses:
        response.requestForRequestForQuotationSubstatuses,
      quotationSubstatuses: response.quotationSubstatuses,
      requestForQuotationSubstatuses: response.requestForQuotationSubstatuses,
      requirementFields: response.requirementFields,
      notificationReminderExecutionPeriod:
        response.notificationReminderExecutionPeriod,
      categoryDimensionTaskStatuses: response.categoryDimensionTaskStatuses,
      strategyDimensionLevers: response.strategyDimensionLevers,
      mdfCodes: response.mdfCodes,
      defaultDimensions: response.defaultDimensions,
      resourceVariables: response.resourceVariables,
      supplierEvaluationEvaluatorRoles:
        response.supplierEvaluationEvaluatorRoles,
    }));
  }

  // eslint-disable-next-line complexity, max-lines-per-function
  private afterDeserialize(data: LookupDataStateShape): LookupDataStateShape {
    return {
      eTag: data?.eTag || '',
      menu: data?.menu.map((item) => new ResourceLinkSlimModel(item)) || [],
      countries: data.countries?.map((item) => new IdNameCodeModel(item)) || [],
      currencies:
        data.currencies?.map((item) => new IdNameCodeModel(item)) || [],
      incotermsTypes:
        data.incotermsTypes?.map((item) => new IdNameCodeModel(item)) || [],
      paymentTerms:
        data.paymentTerms?.map((item) => new IdNameModel(item)) || [],
      termsAndConditionCategories:
        data.termsAndConditionCategories?.map(
          (item) => new TermsAndConditionsCategoryModel(item),
        ) || [],
      wbsCodes: data.wbsCodes?.map((item) => new IdNameModel(item)) || [],
      offeredLeadTimes:
        data.offeredLeadTimes?.map((item) => new OfferedLeadTimeModel(item)) ||
        [],
      deliveryDestinationTypes:
        data.deliveryDestinationTypes?.map(
          (item) => new DeliveryDestinationModel(item),
        ) || [],
      furtherDeliveryDestinationTypes:
        data.furtherDeliveryDestinationTypes?.map(
          (item) => new DeliveryDestinationModel(item),
        ) || [],
      costAllocationTypes:
        data.costAllocationTypes?.map(
          (item) => new CostAllocationModel(item),
        ) || [],
      supplierSendTypes:
        data.supplierSendTypes?.map(
          (item) => new SupplierSendTypeModel(item),
        ) || [],
      projectProcurementTypes:
        data.projectProcurementTypes?.map((item) => new IdNameModel(item)) ||
        [],
      projectClassifications:
        data.projectClassifications?.map((item) => new IdNameModel(item)) || [],
      projectTypeSupplies:
        data.projectTypeSupplies?.map((item) => new IdNameModel(item)) || [],
      projectStatuses:
        data.projectStatuses?.map((item) => new EntityStatusModel(item)) || [],
      projectInExecutionSubstatuses:
        data.projectInExecutionSubstatuses?.map(
          (item) => new IdNameModel(item),
        ) || [],
      requestForRequestForQuotationStatuses:
        data.requestForRequestForQuotationStatuses?.map(
          (item) => new EntityStatusModel(item),
        ) || [],
      requestForQuotationStatuses:
        data.requestForQuotationStatuses?.map(
          (item) => new EntityStatusModel(item),
        ) || [],
      templateRequestForRequestForQuotationStatuses:
        data.templateRequestForRequestForQuotationStatuses?.map(
          (item) => new EntityStatusModel(item),
        ) || [],
      categoryStatuses:
        data.categoryStatuses?.map((item) => new EntityStatusModel(item)) || [],
      purchaseRequisitionStatuses:
        data.purchaseRequisitionStatuses?.map(
          (item) => new EntityStatusModel(item),
        ) || [],
      quotationStatuses:
        data.quotationStatuses?.map((item) => new IdNameModel(item)) || [],
      orderAcknowledgementReviews:
        data.orderAcknowledgementReviews?.map(
          (item) => new OrderAcknowledgementReviewModel(item),
        ) || [],
      contractualMilestones:
        data.contractualMilestones?.map(
          (item) => new ContractualMilestoneModel(item),
        ) || [],
      contractualMilestoneStatuses:
        data.contractualMilestoneStatuses?.map(
          (item) => new ContractualMilestoneStatusModel(item),
        ) || [],
      supplierTypes:
        data.supplierTypes?.map((item) => new SupplierSendTypeModel(item)) ||
        [],
      requestForRequestForQuotationClassifications:
        data.requestForRequestForQuotationClassifications?.map(
          (item) => new IdNameModel(item),
        ) || [],
      requestForRequestForQuotationSubstatuses:
        data.requestForRequestForQuotationSubstatuses?.map(
          (item) => new IdNameModel(item),
        ) || [],
      quotationSubstatuses:
        data.quotationSubstatuses?.map((item) => new IdNameModel(item)) || [],
      requestForQuotationSubstatuses:
        data.requestForQuotationSubstatuses?.map(
          (item) => new IdNameModel(item),
        ) || [],
      requirementFields: data.requirementFields || {
        commercial: [],
        technical: [],
        logistical: [],
      },
      notificationReminderExecutionPeriod:
        data.notificationReminderExecutionPeriod?.map(
          (item) => new IdNameModel(item),
        ) || [],
      categoryDimensionTaskStatuses:
        data.categoryDimensionTaskStatuses?.map(
          (item) => new EntityStatusModel(item),
        ) || [],
      strategyDimensionLevers:
        data.strategyDimensionLevers?.map(
          (item) => new IdNameDescriptionModel(item),
        ) || [],
      mdfCodes: data.mdfCodes?.map((item) => new IdNameCodeModel(item)) || [],
      defaultDimensions:
        data.defaultDimensions?.map(
          (item) => new DefaultCriteriaDimensionModel(item),
        ) || [],
      resourceVariables:
        data.resourceVariables?.map(
          (item) => new ResourceVariablesModel(item),
        ) || [],
      tsp: data.tsp ? new ResourceLinkSlimModel(data.tsp) : null,
      gisLink: data.gisLink ? new ResourceLinkSlimModel(data.gisLink) : null,
      supplierEvaluationEvaluatorRoles:
        data.supplierEvaluationEvaluatorRoles?.map(
          (item) => new IdNameModel(item),
        ) || [],
    };
  }
}
