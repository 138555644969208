import {
  DestroyRef, Injectable, computed, inject, signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { deepEqual } from 'fast-equals';
import { StorageUploadSettingsModel } from '../models/storage-upload-settings.model';
import { DocumentsApiService } from '../services/documents-api.service';

export interface SettingsStateShape {
  storageUploadSettings: StorageUploadSettingsModel | null;
}

const initialState: SettingsStateShape = {
  storageUploadSettings: null,
};

@Injectable({
  providedIn: 'root',
})
export class SettingsState {
  readonly storageUploadSettings = computed(() => this.state().storageUploadSettings, { equal: deepEqual });

  private readonly state = signal({ ...initialState });
  private readonly documentsApiService = inject(DocumentsApiService);
  private readonly destroyRef = inject(DestroyRef);

  fetchStorageUploadSettings(): void {
    this.documentsApiService.getUploadSettings$()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        if (!response) {
          return;
        }

        this.state.update((state) => ({
          ...state,
          storageUploadSettings: response,
        }));
      });
  }
}
