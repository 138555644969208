import * as t from 'io-ts';
import { nullable } from '@abb-procure/api';
import { IdName } from './id-name.type';
import { TermsAndConditions } from './terms-and-conditions.type';

const TermsAndConditionsCategory = t.intersection([
  IdName,
  t.type({
    termsAndConditions: nullable(t.array(TermsAndConditions)),
  }),
], 'TermsAndConditionsCategory');

type TermsAndConditionsCategory = t.TypeOf<typeof TermsAndConditionsCategory>;
export { TermsAndConditionsCategory };
